var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import { DocumentType } from '~/db_types/swagger_types';
import { CURRENT_USER_LS_VARIABLE, DEFAULT_DASH } from '~/nasa_ui/constants';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { store } from '~/nasa_ui/store';
import { CosmicManagedDocumentType, CosmicManagedDocumentTypeDisplay, CosmicUserSupportContext, EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils';
import { getAssetPath } from '~/nasa_ui/utils/helpers/assets';
import LS from '~/nasa_ui/utils/LocalStorageService';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
let TheNavBar = class TheNavBar extends Mixins(CurrentUserMixin) {
    alertLimit = 9;
    ariaSelected = false;
    isHoveringOverNewTab = false;
    isHoveringOverHamburger = false;
    navOpenState = null;
    showFavoritesModal = false;
    userNeedsSessionInterval = null;
    createMenuItems = [
        // Approval Routing template
        {
            destinationUrl: `/${EntityType.USER}/${EntityType.APPROVAL_ROUTING_TEMPLATE}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.APPROVAL_ROUTING_TEMPLATE) || '',
            icon: CosmicIcons[EntityType.APPROVAL_ROUTING_TEMPLATE],
            permissionToCheck: UserPermission.APPROVAL_ROUTING_TEMPLATE_ADMIN,
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`,
            supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.ESOC, CosmicUserSupportContext.xEMU]
        },
        // TPS
        {
            destinationUrl: `/${CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_TPS)}/create`,
            displayText: CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_TPS) || '',
            icon: CosmicIcons[CosmicManagedDocumentType.C_TPS],
            permissionToCheck: UserPermission.C_DOCS_TPS_ADMIN,
            supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
            textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        },
        // DR
        // {
        //   destinationUrl: `/${CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_DISCREPANCY_REPORT)}/create`,
        //   displayText: CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_DISCREPANCY_REPORT) || '',
        //   icon: CosmicIcons[CosmicManagedDocumentType.C_DISCREPANCY_REPORT],
        //   permissionToCheck: UserPermission.C_DOCS_DR_ADMIN,
        //   supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
        //   textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        // },
        // GCAR
        {
            destinationUrl: `/cgcar/create`,
            displayText: 'cGCAR',
            icon: CosmicIcons[DocumentType.GCAR],
            permissionToCheck: UserPermission.C_DOCS_GCAR_ADMIN,
            supportContext: [CosmicUserSupportContext.ESOC, CosmicUserSupportContext.EHP],
            textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        },
        // FRACAS
        // {
        //   destinationUrl: `/${CosmicManagedDocumentTypeDisplay.get(
        //     CosmicManagedDocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM
        //   )}/create`,
        //   displayText:
        //     CosmicManagedDocumentTypeDisplay.get(
        //       CosmicManagedDocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM
        //     ) || '',
        //   icon: CosmicIcons[CosmicManagedDocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM],
        //   permissionToCheck: UserPermission.C_DOCS_FRACAS_ADMIN,
        //   supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
        //   textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        // },
        // EZT
        // {
        //   destinationUrl: `/${CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_EASY_TRANSFER)}/create`,
        //   displayText: CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_EASY_TRANSFER) || '',
        //   icon: CosmicIcons[CosmicManagedDocumentType.C_EASY_TRANSFER],
        //   permissionToCheck: UserPermission.C_DOCS_EZT_ADMIN,
        //   supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
        //   textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        // },
        // RCA
        {
            destinationUrl: `/${CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_RCA)}/create`,
            displayText: CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_RCA) || '',
            icon: CosmicIcons[CosmicManagedDocumentType.C_RCA],
            permissionToCheck: UserPermission.C_DOCS_RCA_ADMIN,
            supportContext: [CosmicUserSupportContext.ESOC],
            textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        },
        // WORKFLOW
        {
            destinationUrl: `/${CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_WORKFLOW)}/create`,
            displayText: CosmicManagedDocumentTypeDisplay.get(CosmicManagedDocumentType.C_WORKFLOW) || '',
            icon: CosmicIcons[CosmicManagedDocumentType.C_WORKFLOW],
            permissionToCheck: UserPermission.C_DOCS_WORKFLOWS_ADMIN,
            supportContext: [CosmicUserSupportContext.ESOC],
            textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        },
        // Location
        {
            destinationUrl: `/${EntityType.LOCATION}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.LOCATION) || '',
            icon: CosmicIcons[EntityType.LOCATION],
            permissionToCheck: UserPermission.LOCATION_ADMIN,
            textColor: `var(--v-${EntityType.LOCATION}-base)`,
            supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.ESOC, CosmicUserSupportContext.xEMU]
        },
        // Create User
        {
            destinationUrl: `/${EntityType.USER}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.USER) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.USER],
            permissionToCheck: UserPermission.USER_ADMIN,
            textColor: `var(--v-${EntityType.USER}-base)`,
            supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.ESOC, CosmicUserSupportContext.xEMU]
        },
        // User Group
        {
            destinationUrl: '/users/groups/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.USER_GROUP) || '',
            icon: CosmicIcons[EntityType.USER_GROUP],
            permissionToCheck: UserPermission.USER_GROUPS_ADMIN,
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`,
            supportContext: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.ESOC, CosmicUserSupportContext.xEMU]
        }
    ];
    outageAlertState;
    get cosmicDocumentsLogoSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/documents_app_logo.svg`;
    }
    get computedCreateMenuItemsBasedOnCtx() {
        return this.createMenuItems.filter((item) => {
            // if you didnt list one its ok
            if (!item.supportContext) {
                return true;
            }
            // if you left it empty let em all in
            if (item.supportContext.length === 0) {
                return true;
            }
            return item.supportContext.includes(this.currentUserActiveSupportContext);
        });
    }
    get logoStyle() {
        return {};
    }
    get navbarSettings() {
        return (this.currentUserActiveSupportContext &&
            this.currentUser?.attributes?.navbar?.[this.currentUserActiveSupportContext]);
    }
    get shouldShowCalibrationsLink() {
        return isNullOrUndefined(this.navbarSettings?.showCalibrationsLink)
            ? true
            : this.navbarSettings?.showCalibrationsLink;
    }
    get shouldShowFavoritesLink() {
        return isNullOrUndefined(this.navbarSettings?.showFavoritesLink) ? true : this.navbarSettings?.showFavoritesLink;
    }
    get shouldShowLimitedLifeLink() {
        return isNullOrUndefined(this.navbarSettings?.showLimitedLifeLink)
            ? true
            : this.navbarSettings?.showLimitedLifeLink;
    }
    get shouldShowMyApprovals() {
        return isNullOrUndefined(this.navbarSettings?.showMyApprovals) ? true : this.navbarSettings?.showMyApprovals;
    }
    get shouldShowNewBrowserWindowIcon() {
        return isNullOrUndefined(this.navbarSettings?.showNewBrowserWindowIcon)
            ? true
            : this.navbarSettings?.showNewBrowserWindowIcon;
    }
    get shouldShowUserSupport() {
        return isNullOrUndefined(this.navbarSettings?.showUserSupport) ? true : this.navbarSettings?.showUserSupport;
    }
    get usersManageLink() {
        return `/users/manage/${this.currentUser?.id}#settings`;
    }
    closeTheSideNav() {
        this.navOpenState = false;
    }
    created() {
        this.navOpenState = localStorage.getItem('navOpenState') === 'true';
        this.$listen('set-side-nav', this.handleSideNav);
        this.$listen('closeTheSideNav', () => this.closeTheSideNav());
        // listen for highlighted navbar items from user settings
        this.$listen('navbar_item_highlighted', (itemName) => {
            this.highlightNavbarItem(itemName);
        });
        this.$listen('user_updated', (user) => {
            LS.setWithEncoding(CURRENT_USER_LS_VARIABLE, user);
            store.currentUser = user;
        });
    }
    handleLaunchpadSessionAuthorizeClick() {
        window.open('/api/oauth/authorize', '_blank');
    }
    handleSideNav(val) {
        this.navOpenState = val;
    }
    highlightNavbarItem(itemRefName) {
        if (itemRefName && this.$refs[itemRefName]) {
            this.$refs[itemRefName].classList.add('highlight');
        }
        else {
            this.$el.querySelectorAll('.nav_bar_item').forEach((item) => {
                item?.classList.remove('highlight');
            });
        }
    }
    toggleSideNavigationOpenState() {
        this.navOpenState = !this.navOpenState;
    }
    onClickOfCosmicLogo(event) {
        // already at home
        if (!event.metaKey && !event.ctrlKey && this.$route.fullPath === '/') {
            event.preventDefault();
            return;
        }
        if (!event.metaKey && !event.ctrlKey) {
            event.preventDefault();
            this.$router.push('/');
        }
    }
    toggleTheSideNavigationOpenState() {
        this.navOpenState = !this.navOpenState;
        this.ariaSelected = !this.ariaSelected;
    }
    emitNavOpenState() {
        return this.navOpenState;
    }
    navigateTo(path) {
        this.$router.push({ path, query: {} });
    }
};
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", Object)
], TheNavBar.prototype, "outageAlertState", void 0);
__decorate([
    Emit('nav-open-state'),
    Watch('navOpenState'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TheNavBar.prototype, "emitNavOpenState", null);
TheNavBar = __decorate([
    Component
], TheNavBar);
export default TheNavBar;
