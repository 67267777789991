var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-navigation-drawer', {
    staticClass: "the_side_nav",
    class: {
      'outage-error': _vm.outageAlertState === _vm.AlertType.ERROR,
      'outage-info': _vm.outageAlertState === _vm.AlertType.INFO,
      'outage-warning': _vm.outageAlertState === _vm.AlertType.WARNING
    },
    attrs: {
      "value": _vm.value,
      "app": "",
      "aria-orientation": "vertical",
      "disable-resize-watcher": ""
    },
    on: {
      "input": _vm.emitNavDrawerValue
    }
  }, [_c('v-list', {
    staticClass: "pt-0"
  }, [_c('v-list-tile', {
    staticClass: "nav_item reporting_menu_selection",
    class: {
      nav_item_active: _vm.$route.path.startsWith('/reporting')
    },
    attrs: {
      "active-class": "",
      "to": "/reporting"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fad fa-chart-line",
      "icon-color": _vm.$route.path.startsWith('/reporting') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Reporting")])], 1)], 1), _vm.shouldShow(_vm.DocumentType.C_RCA) ? _c('v-list-tile', {
    staticClass: "nav_item rca_menu_selection",
    class: {
      is_actually_active: _vm.isRouteRca
    },
    attrs: {
      "active-class": "",
      "exact": "",
      "to": "/search?documentSubType=C_RCA"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons[_vm.DocumentType.C_RCA],
      "icon-color": _vm.isRouteRca ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("cRCA")])], 1)], 1) : _vm._e(), _vm.shouldShow(_vm.DocumentType.C_WORKFLOW) ? _c('v-list-tile', {
    staticClass: "nav_item workflow_menu_selection",
    class: {
      is_actually_active: _vm.isRouteWorkflow
    },
    attrs: {
      "active-class": "",
      "exact": "",
      "to": "/search?documentSubType=C_WORKFLOW"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons[_vm.DocumentType.C_WORKFLOW],
      "icon-color": _vm.isRouteWorkflow ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("cWORKFLOW")])], 1)], 1) : _vm._e(), _vm.shouldShowJf288 ? _c('v-list-tile', {
    staticClass: "nav_item workflow_menu_selection",
    class: {
      nav_item_active: _vm.isRouteJf288
    },
    attrs: {
      "to": "/jf288/manage",
      "active-class": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons.jf288,
      "icon-color": _vm.isRouteJf288 ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("JF288")])], 1)], 1) : _vm._e(), _c('v-list-tile', {
    staticClass: "nav_item",
    class: {
      nav_item_active: _vm.isRoutePendingApprovals
    },
    attrs: {
      "to": `/reporting/pending_approvals`,
      "active-class": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons[_vm.EntityType.CHANGE_SET],
      "icon-color": _vm.isRoutePendingApprovals ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("Pending Approvals")])], 1)], 1), _vm.currentUserIsAdmin || _vm.currentUserIsSystem ? _c('v-list', [_c('v-list-group', {
    attrs: {
      "prepend-icon": _vm.$icons.cog,
      "no-action": "",
      "dark": ""
    }
  }, [_c('v-list-tile', {
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Admin")])], 1)], 1), _c('v-list-tile', {
    attrs: {
      "to": "/admin",
      "exact": "",
      "exact-active-class": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Admin")])], 1), _c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons['ADMIN'],
      "icon-color": "var(--v-secondary-base)",
      "icon-size": "21px"
    }
  })], 1)], 1), _c('v-list-tile', {
    attrs: {
      "to": `/${_vm.EntityType.USER}/search`,
      "exact": "",
      "exact-active-class": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Manage Users")])], 1), _c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.USER],
      "icon-color": "var(--v-users-base)",
      "icon-size": "21px"
    }
  })], 1)], 1), _c('v-list-tile', {
    attrs: {
      "to": `/${_vm.EntityType.USER}/groups/search`,
      "exact": "",
      "exact-active-class": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Manage User Groups")])], 1), _c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.USER_GROUP],
      "icon-color": "var(--v-user_groups-base)",
      "icon-size": "21px"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-list-tile', {
    staticClass: "nav_item change_log",
    on: {
      "click": _vm.onClickOfShowChangeLog
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fa-thin fa-wand-magic-sparkles"
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("View app updates")])], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "text-xs-center monospace_font primary white--text font-weight-bold app_version"
  }, [_vm._v("v" + _vm._s(_vm.appVersion))])], 1)], 1), _c('hr'), _c('v-list-tile', {
    staticClass: "nav_item cosmic_menu_selection",
    attrs: {
      "href": _vm.ogUrl
    }
  }, [_c('v-list-tile-action', {
    staticClass: "external_link"
  }, [_c('img', {
    attrs: {
      "height": "24",
      "src": _vm.cosmicDocumentsLogoSrc,
      "alt": "Navigate to COSMIC"
    }
  }), _c('c-icon-fa', {
    staticClass: "ml-3",
    attrs: {
      "icon": _vm.$icons['external_link'],
      "icon-size": "12px",
      "icon-color": "black"
    }
  })], 1)], 1), _vm.showChangeLog ? _c('ModalChangeLog', {
    on: {
      "modal-close": function ($event) {
        _vm.showChangeLog = !_vm.showChangeLog;
      }
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };