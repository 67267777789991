import { DocumentType, LinkType } from '~/db_types';
import { EntityType, EsocApplication, getEnvironment, UserPermission } from '~/nasa_ui/types';
import { CosmicSystem, CosmicUserSupportContext } from '~/nasa_ui/types/cosmic/system';
// Defining system config which is used for example by routing
export const COSMIC_SYSTEM = CosmicSystem.ESOC;
export const COSMIC_APPLICATION = EsocApplication.CDOCS;
export const COSMIC_ENVIRONMENT = getEnvironment();
// Every valid support context for this application
export var UserSupportContext;
(function (UserSupportContext) {
    UserSupportContext["CTSD"] = "CTSD";
    UserSupportContext["ESOC"] = "ESOC";
    UserSupportContext["xEMU"] = "xEMU";
    UserSupportContext["EHP"] = "EHP";
})(UserSupportContext || (UserSupportContext = {}));
// Listing out, per UserSupportContext, which are valid DocumentTypes
export const UserSupportContextToValidDocumentTypes = new Map([
    [CosmicUserSupportContext.CTSD, [DocumentType.C_TPS]],
    [CosmicUserSupportContext.ESOC, [DocumentType.C_WORKFLOW, DocumentType.C_RCA]],
    [CosmicUserSupportContext.EHP, [DocumentType.C_TPS, DocumentType.GCAR]],
    [CosmicUserSupportContext.xEMU, [DocumentType.C_TPS]]
]);
// Listing out, per UserSupportContext, which are valid EntityTypes
export const UserSupportContextToValidEntityTypes = new Map([
    [CosmicUserSupportContext.CTSD, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.ESOC, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.xEMU, [EntityType.DOCUMENT]],
    [CosmicUserSupportContext.EHP, [EntityType.DOCUMENT]]
]);
// Listing out, per UserSupportContext, which are valid LinkTypes
export const UserSupportContextToValidLinkTypes = new Map([
    [CosmicUserSupportContext.CTSD, [LinkType.OTHER]],
    [CosmicUserSupportContext.ESOC, [LinkType.OTHER]],
    [CosmicUserSupportContext.xEMU, [LinkType.OTHER]],
    [CosmicUserSupportContext.EHP, [LinkType.OTHER]]
]);
// per UserSupportContext, valid UserPermissions
const BASE_PERMISSIONS = [
    UserPermission.APPROVAL_ROUTING_TEMPLATE_ADMIN,
    UserPermission.CHANGE_SET_DELETE,
    UserPermission.CONTACT_ADMIN,
    UserPermission.DOCUMENT_ADMIN,
    UserPermission.HARDWARE_LIST_ADMIN,
    UserPermission.LOCATION_ADMIN,
    UserPermission.USER_ADMIN,
    UserPermission.USER_GROUPS_ADMIN,
    UserPermission.USER_PERMISSIONS,
    UserPermission.USER_SUPPORT_CONTEXT
];
export const UserSupportContextToValidPermissions = new Map([
    [
        CosmicUserSupportContext.CTSD,
        [
            ...BASE_PERMISSIONS,
            UserPermission.C_DOCS_DR_ADMIN,
            UserPermission.C_DOCS_EZT_ADMIN,
            UserPermission.C_DOCS_FRACAS_ADMIN,
            UserPermission.C_DOCS_TPS_ADMIN
        ]
    ],
    [
        CosmicUserSupportContext.ESOC,
        [
            ...BASE_PERMISSIONS,
            UserPermission.C_DOCS_RCA_ADMIN,
            UserPermission.C_DOCS_RCA_SUBMITTER,
            UserPermission.C_DOCS_WORKFLOWS_ADMIN,
            UserPermission.C_DOCS_WORKFLOWS_SUBMITTER,
            UserPermission.C_DOCS_GCAR_ADMIN // TODO: Delete after adoption
        ]
    ],
    [
        CosmicUserSupportContext.xEMU,
        [
            ...BASE_PERMISSIONS,
            UserPermission.C_DOCS_DR_ADMIN,
            UserPermission.C_DOCS_EZT_ADMIN,
            UserPermission.C_DOCS_FRACAS_ADMIN,
            UserPermission.C_DOCS_TPS_ADMIN
        ]
    ],
    [CosmicUserSupportContext.EHP, [...BASE_PERMISSIONS, UserPermission.C_DOCS_GCAR_ADMIN]]
]);
// export installation method for Vue so its on the Vue prototype
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $cosmicSystem: { value: COSMIC_SYSTEM },
            $cosmicEnvironment: { value: COSMIC_ENVIRONMENT },
            $validUserSupportContexts: { value: Object.values(UserSupportContext) }
        });
    }
};
