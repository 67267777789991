import { HeaderSortOptions } from '~/nasa_ui/types';
import { composeTableHeader } from '../utils/helpers/composeTableHeader';
/****************************************************************
 * These are BASE cols intented to be overridden using
 * `composeTableHeader` or `Object.assign`
 ****************************************************************/
export const BASE_USER_COL = {
    headerSortType: HeaderSortOptions.TEXT,
    noEscape: true,
    noWrap: true,
    sortable: true
};
export const BASE_DATE_COL = {
    align: 'left',
    headerSortType: HeaderSortOptions.DATE,
    monospacedFont: true,
    noEscape: true,
    noWrap: true,
    sortable: true,
    width: '130px'
};
// Extends upon BASE_DATE_COL
export const BASE_DATE_TIME_COL = composeTableHeader(BASE_DATE_COL, {
    headerSortType: HeaderSortOptions.DATETIME
});
export const BASE_ICON_COL = {
    align: 'center',
    noEscape: true,
    sortable: true
};
export const BASE_NUMBER_COL = {
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER,
    monospacedFont: true,
    sortable: true
};
export const BASE_TEXT_COL = {
    align: 'left',
    headerSortType: HeaderSortOptions.TEXT,
    monospacedFont: false,
    sortable: true
};
export const BASE_BLOCK_OF_TEXT_COL = {
    align: 'left',
    headerSortType: HeaderSortOptions.BLOCK_OF_TEXT,
    monospacedFont: false,
    noEscape: true,
    sortable: false
};
/****************************************************************
 * Individual Column constants
 ****************************************************************/
export const ADDENDUM_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Addendum',
    value: '_addendumDate'
});
export const ADDENDUM_NUM_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    text: 'Addendum<br/>number',
    value: '_addendumNumber'
});
export const ATTACHMENT_SUBTYPE_COL = composeTableHeader(BASE_TEXT_COL, {
    text: 'Attachment<br/>type',
    value: '_attachmentSubType',
    width: '300px'
});
export const ATTACHMENT_DESCRIPTION_COL = composeTableHeader(BASE_TEXT_COL, {
    text: 'Attachment<br/>description',
    value: '_attachmentDescription'
});
export const ATTACHMENT_FILE_TYPE_COL = {
    text: 'File type',
    value: '_attachmentContentType'
};
export const ATTACHMENT_FILE_TYPE_ICON_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'File type',
    value: '_attachmentContentTypeIcon'
});
export const ATTRIBUTES_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    noWrap: true,
    text: 'Attributes',
    value: '_attributes'
});
export const AUTHORIZED_BY_COL = composeTableHeader(BASE_USER_COL, {
    text: 'Authorized by',
    value: '_authorizedBy',
    width: '200px'
});
// This is usually a combo of authorityCode + authorityNumber
export const AUTHORITY_COL = composeTableHeader(BASE_TEXT_COL, {
    noWrap: true,
    noEscape: true,
    text: 'Authority',
    value: '_authority'
});
export const AUTHORIZED_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Authorized',
    value: '_authorizedDate'
});
export const AUTHORITY_NUMBER = composeTableHeader(BASE_TEXT_COL, {
    monospacedFont: true,
    text: 'Authority</br>number',
    value: '_authorityNumber'
});
export const AS_BUILT_COL = composeTableHeader(BASE_TEXT_COL, {
    align: 'right',
    monospacedFont: true,
    noEscape: true,
    text: 'AsBuilt',
    value: '_asBuiltNumber'
});
export const AS_DESIGN_COL = composeTableHeader(AS_BUILT_COL, {
    text: 'AsDesign',
    value: '_asDesignNumber'
});
export const BUILDING_COL = composeTableHeader(BASE_TEXT_COL, {
    monospacedFont: true,
    text: 'Building',
    value: '_building'
});
export const BUILDING_BIN_STOCK_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    noWrap: true,
    text: 'Building / Stock / Bin',
    value: '_buildingStockBin'
});
export const CLASS_COL = composeTableHeader(BASE_TEXT_COL, {
    align: 'center',
    monospacedFont: true,
    noWrap: true,
    text: 'Class',
    value: '_subType'
});
export const CLOSE_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Close',
    value: '_closeDate'
});
export const CLOSED_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Closed',
    value: '_computedCloseDate'
});
export const CLOSED_BY_USER_COL = composeTableHeader(BASE_USER_COL, {
    text: 'Closed by',
    value: '_closedByUser'
});
export const COMMENT_COL = composeTableHeader(BASE_BLOCK_OF_TEXT_COL, {
    noEscape: true,
    text: 'Comment',
    value: '_comment'
});
export const COMMENTS_COL = composeTableHeader(COMMENT_COL, {
    text: 'Comments',
    value: '_comments'
});
export const CREATED_BY_COL = composeTableHeader(BASE_USER_COL, {
    text: 'Created by',
    value: '_createdByUser'
});
export const CREATED_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Created',
    value: '_createdDateTime'
});
export const CREATED_DATE_TIME_COL = composeTableHeader(BASE_DATE_TIME_COL, {
    text: 'Created',
    value: '_createdDateTimeWithBreak'
});
export const CREW_TIME_COL = composeTableHeader(BASE_TEXT_COL, {
    noWrap: true,
    noEscape: true,
    text: 'Crew<br/>time',
    value: '_crewTime'
});
export const CYCLES_COL = composeTableHeader(BASE_NUMBER_COL, {
    text: 'Cycles',
    value: '_cycles'
});
export const CURRENTLY_ACTIVE_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'Currently<br/>active?',
    value: '_isActiveIcon'
});
export const DESCRIPTION_COL = composeTableHeader(BASE_TEXT_COL, {
    headerSortType: HeaderSortOptions.BLOCK_OF_TEXT,
    noEscape: true,
    text: 'Description',
    value: '_description',
    width: '350px'
});
export const DRAWING_NUMBER_COL = composeTableHeader(BASE_TEXT_COL, {
    align: 'left',
    monospacedFont: true,
    noWrap: true,
    text: 'Drawing number',
    value: 'drawingNumber'
});
export const DUE_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Due',
    value: '_dueDate'
});
export const END_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'End',
    value: '_endDate'
});
export const IS_EXTERNAL_ICON_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'External?',
    value: '_isExternal'
});
export const FILE_NAME_COL = composeTableHeader(BASE_TEXT_COL, {
    text: 'File name',
    value: '_fileName'
});
export const FILE_TYPE_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    text: 'File type',
    value: '_downloadLink'
});
export const FILE_TYPE_ICON_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'File type',
    value: '_fileIcon'
});
export const FROM_LOCATION_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    text: 'From<br/>Location',
    value: '_fromLocation'
});
export const HEIGHT_COL = {
    align: 'right',
    monospacedFont: true,
    sortable: false,
    text: 'Height',
    value: '_height'
};
export const INITIATOR_CONTACT_COL = composeTableHeader(BASE_USER_COL, {
    text: 'Initiator',
    value: '_initiatorContact'
});
export const INITIALIZATION_STATUS_ICON_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'Initialization<br/>status',
    value: '_initializationStatusIcon'
});
export const INVENTORY_QTY_COL = {
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER,
    monospacedFont: true,
    noWrap: true,
    sortable: true,
    text: 'Inv<br/>qty',
    value: '_inventoryQuantity'
};
export const IS_INSTALLED_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'Installed?',
    value: '_isInstalled'
});
export const ITEM_NUMBER_COL = composeTableHeader(BASE_TEXT_COL, {
    align: 'left',
    monospacedFont: true,
    noEscape: true,
    noWrap: true,
    text: 'Item number',
    value: '_itemNumber'
});
export const LENGTH_COL = {
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER,
    monospacedFont: true,
    sortable: false,
    text: 'Length',
    value: '_length'
};
export const LOT_COL = composeTableHeader(BASE_TEXT_COL, {
    align: 'right',
    monospacedFont: true,
    noEscape: true,
    noWrap: true,
    text: 'Lot',
    value: '_lotNumber'
});
export const NAME_COL = composeTableHeader(BASE_TEXT_COL, {
    text: 'Name',
    value: 'name'
});
export const NAME_DISPLAY_COL = composeTableHeader(NAME_COL, {
    value: '_name'
});
export const NOTES_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    noWrap: true,
    text: 'Notes',
    value: '_notes'
});
export const NUMBER_COL = {
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER,
    monospacedFont: true,
    noWrap: true,
    sortable: true,
    text: 'Number',
    value: '_number',
    width: '125px'
};
export const NUMBER_NO_UNDERSCORE_COL = composeTableHeader(NUMBER_COL, {
    value: 'number'
});
export const OPENED_BY_USER_COL = composeTableHeader(BASE_USER_COL, {
    text: 'Opened by',
    value: '_openedByUser'
});
export const OPEN_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Open',
    value: '_openDate'
});
export const OPENED_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Opened',
    value: '_computedOpenDate'
});
export const OPERATION_TIME_COL = composeTableHeader(BASE_TEXT_COL, {
    noWrap: true,
    noEscape: true,
    text: 'Op<br/>time',
    value: '_operationTime'
});
export const PART_NUMBER_COL = composeTableHeader(BASE_TEXT_COL, {
    align: 'left',
    monospacedFont: true,
    noEscape: true,
    noWrap: true,
    text: 'Part number',
    value: '_partNumber'
});
export const PRESSURIZATION_RANGE_COL = composeTableHeader(BASE_TEXT_COL, {
    noWrap: true,
    noEscape: true,
    text: 'Pressurization<br/>range',
    value: '_pressurizationRange'
});
export const PRESSURIZATION_TIME_COL = composeTableHeader(BASE_TEXT_COL, {
    noWrap: true,
    noEscape: true,
    text: 'Pressurized<br/>time',
    value: '_pressurizedTime'
});
export const PREVENTATIVE_MAINTENANCE_ICON_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'Preventative<br/>maintenance?',
    value: '_preventativeMaintenance'
});
export const PRODUCT_OWNER_COL = composeTableHeader(BASE_USER_COL, {
    text: 'Product owner',
    value: '_productOwner'
});
export const PROJECT_CODE_COL = composeTableHeader(BASE_TEXT_COL, {
    align: 'right',
    monospacedFont: true,
    noEscape: true,
    text: 'Project<br/>Code',
    value: '_projectCode'
});
export const PROJECT_CODES_COL = {
    headerSortType: HeaderSortOptions.TEXT,
    monospacedFont: true,
    noEscape: true,
    sortable: true,
    text: 'Project<br/>Codes',
    value: '_projectCodes'
};
export const QUANTITY_COL = composeTableHeader(BASE_NUMBER_COL, {
    text: 'Qty',
    value: '_quantity'
});
export const REVISION_COL = composeTableHeader(BASE_TEXT_COL, {
    text: 'Revision',
    value: '_revision'
});
export const REVISION_NUMBER_COL = composeTableHeader(REVISION_COL, {
    value: '_revisionNumber',
    width: '100px'
});
export const REVISION_DATE_COL = composeTableHeader(BASE_DATE_TIME_COL, {
    text: 'Revision<br/>date',
    value: '_revisionDateTime'
});
// Do whatever you lot number is doing
export const SERIAL_COL = composeTableHeader(LOT_COL, {
    text: 'Serial',
    value: '_serialNumber'
});
export const SEQUENCE_COL = {
    align: 'right',
    headerSortType: HeaderSortOptions.NUMBER,
    monospacedFont: true,
    noWrap: true,
    sortable: true,
    text: 'Seq',
    value: '_sequence',
    width: '100px'
};
export const SHELF_LIFE_EXP_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Shelf life exp.',
    value: '_shelfLifeExpirationDate'
});
export const SIDE_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    text: 'Side',
    value: '_side'
});
export const SIZE_COL = composeTableHeader(BASE_TEXT_COL, {
    text: 'Size',
    monospacedFont: true,
    value: '_size',
    width: '75px'
});
export const START_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Start',
    value: '_startDate'
});
export const STATIC_TIME_COL = composeTableHeader(BASE_TEXT_COL, {
    noWrap: true,
    noEscape: true,
    text: 'Static<br/>time',
    value: '_staticTime'
});
export const STATUS_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    noWrap: true,
    text: 'Status',
    value: '_status'
});
export const STATUS_ICON_COL = composeTableHeader(BASE_ICON_COL, {
    text: 'Status',
    value: '_statusIcon'
});
export const SUB_TYPE_COL = composeTableHeader(BASE_TEXT_COL, {
    noWrap: true,
    text: 'Type',
    value: '_subType'
});
export const TO_LOCATION_COL = composeTableHeader(FROM_LOCATION_COL, {
    text: 'To<br/>Location',
    value: '_toLocation'
});
export const TITLE_COL = composeTableHeader(BASE_TEXT_COL, {
    noEscape: true,
    noWrap: false,
    text: 'Title',
    value: '_title',
    width: '250px'
});
export const UNIT_COST_COL = {
    align: 'right',
    headerSortType: HeaderSortOptions.CURRENCY,
    monospacedFont: true,
    sortable: true,
    text: 'Unit<br/>cost',
    value: '_unitCost'
};
export const UPDATED_BY_COL = composeTableHeader(BASE_USER_COL, {
    text: 'Updated by',
    value: '_updatedByUser'
});
export const UPDATED_DATE_COL = composeTableHeader(BASE_DATE_COL, {
    text: 'Updated',
    value: '_updatedDateTime'
});
export const UPDATED_DATE_TIME_COL = composeTableHeader(BASE_DATE_TIME_COL, {
    text: 'Updated on',
    value: '_updatedDateTimeWithBreak'
});
export const WIDTH_COL = composeTableHeader(HEIGHT_COL, {
    text: 'Width',
    value: '_width'
});
export const WEIGHT_COL = composeTableHeader(HEIGHT_COL, {
    text: 'Weight',
    value: '_weight'
});
