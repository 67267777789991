var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import { DocumentType, Maybe } from '~/db_types';
import BaseView from '~/nasa_ui/base/BaseView';
import { CosmicUserSupportContext } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { sortObjectBy } from '~/nasa_ui/utils';
import { getAssetPath } from '~/nasa_ui/utils/helpers/assets';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
import { getOgUrl } from '~/nasa_ui/utils/url';
import pkg from '../../../package.json';
let TheSideNav = class TheSideNav extends BaseView {
    CosmicIcons = CosmicIcons;
    DocumentType = DocumentType;
    appVersion = pkg.version;
    menuFilterText = '';
    routes = [];
    showChangeLog = false;
    value;
    outageAlertState;
    get computedFilteredNavList() {
        const routes = this.menuFilterText
            ? this.routes.filter((route) => route.displayName.toLowerCase().includes(this.menuFilterText.toLowerCase()))
            : this.routes;
        return routes.sort(sortObjectBy('displayName'));
    }
    get cosmicDocumentsLogoSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/main_app_logo_light.svg`;
    }
    get isRouteJf288() {
        return this.$route.path.startsWith(`/jf288`);
    }
    get isRouteDeliveryMatrix() {
        return this.$route.path.startsWith(`/${this.EntityType.DELIVERY_MATRIX_ITEM}`);
    }
    get isRouteDr() {
        return (this.$route.path.startsWith(`/${DocumentTypeDisplay.get(DocumentType.C_DISCREPANCY_REPORT)}`) ||
            (this.$route.path.startsWith('/search') &&
                this.$route.query.documentSubType === DocumentType.C_DISCREPANCY_REPORT));
    }
    get isRouteEzt() {
        return (this.$route.path.startsWith(`/${DocumentTypeDisplay.get(DocumentType.C_EASY_TRANSFER)}`) ||
            (this.$route.path.startsWith('/search') && this.$route.query.documentSubType === DocumentType.C_EASY_TRANSFER));
    }
    get isRouteFracas() {
        return (this.$route.path.startsWith(`/${DocumentTypeDisplay.get(DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM)}`) ||
            (this.$route.path.startsWith('/search') &&
                this.$route.query.documentSubType === DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM));
    }
    get isRoutePendingApprovals() {
        return this.$route.path.startsWith('/reporting/pending_approvals');
    }
    get isRouteTps() {
        return (this.$route.path.startsWith(`/${DocumentTypeDisplay.get(DocumentType.C_TPS)}`) ||
            (this.$route.path.startsWith('/search') && this.$route.query.documentSubType === DocumentType.C_TPS));
    }
    get isRouteRca() {
        return (this.$route.path.startsWith(`/${DocumentTypeDisplay.get(DocumentType.C_RCA)}`) ||
            (this.$route.path.startsWith('/search') && this.$route.query.documentSubType === DocumentType.C_RCA));
    }
    get isRouteWorkflow() {
        return (this.$route.path.startsWith(`/${DocumentTypeDisplay.get(DocumentType.C_WORKFLOW)}`) ||
            (this.$route.path.startsWith('/search') && this.$route.query.documentSubType === DocumentType.C_WORKFLOW));
    }
    get ogUrl() {
        return getOgUrl();
    }
    // This needs a separate method since Jf288 is not a document type
    get shouldShowJf288() {
        return this.currentUserActiveSupportContext === CosmicUserSupportContext.ESOC;
    }
    close() {
        this.$message('set-side-nav', false);
    }
    onClickOfShowChangeLog() {
        this.showChangeLog = true;
    }
    shouldShow(documentSubType) {
        if (!this.currentUserActiveSupportContext) {
            return false;
        }
        const validTypesForCurrentlyActiveCtx = this.currentUserActiveSupportContextValidDocumentTypes;
        if (!validTypesForCurrentlyActiveCtx) {
            console.log(`No valid Document types found for ctx: ${this.currentUserActiveSupportContext}`);
            return false;
        }
        return validTypesForCurrentlyActiveCtx.includes(documentSubType);
    }
    emitNavDrawerValue(val) { }
    onOpenStateChange(val) {
        if (val) {
            const sideNav = document.querySelector('.the_side_nav');
            if (sideNav) {
                sideNav.scrollTop = 0;
            }
            const textEl = document.querySelector('.nav_filter input');
            if (textEl) {
                textEl.focus();
            }
        }
    }
};
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], TheSideNav.prototype, "value", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], TheSideNav.prototype, "outageAlertState", void 0);
__decorate([
    Emit('sideNavValue'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "emitNavDrawerValue", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "onOpenStateChange", null);
TheSideNav = __decorate([
    Component
], TheSideNav);
export default TheSideNav;
