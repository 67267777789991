import Vue from 'vue';
import VueRouter from 'vue-router';
import { ContactSharedRoutes, HardwareListSharedRoutes, LocationSharedRoutes, NonnominalSharedRoutes, UserSharedRoutes } from '~/nasa_ui/router';
import { AdminSharedRoutes } from '~/nasa_ui/router/admin';
import { CosmicUserSupportContext, EntityType, UserPermission } from '~/nasa_ui/types';
import HomeView from '~/views/HomeView/HomeView.vue';
import { RouterLogUtility } from '../utils';
Vue.use(VueRouter);
// const EZT_ROUTES: CosmicRouteConfig[] = [
//   {
//     path: `/cezt/search`,
//     redirect: {
//       name: 'DocumentSearchView',
//       query: {
//         documentSubType: 'C_EASY_TRANSFER'
//       }
//     },
//     meta: {
//       description: 'COSMIC EZT creation',
//       displayName: 'COSMIC - cEZT creation',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   },
//   {
//     path: `/cezt/create`,
//     name: 'EzTransferCreateView',
//     component: () => import(/* webpackChunkName: "DocsEzt" */ '../views/EzTransferCreateView/EzTransferCreateView.vue'),
//     meta: {
//       description: 'COSMIC EZT creation',
//       displayName: 'COSMIC - cEZT creation',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   },
//   {
//     path: `/cezt/manage`,
//     redirect: {
//       name: 'DocumentSearchView',
//       query: {
//         documentSubType: 'C_EASY_TRANSFER'
//       }
//     },
//     meta: {
//       description: 'COSMIC EZT search',
//       displayName: 'COSMIC - cEZT search',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   },
//   {
//     path: `/cezt/manage/:id`,
//     name: 'EzTransferManageView',
//     component: () => import(/* webpackChunkName: "DocsEzt" */ '../views/EzTransferManageView/EzTransferManageView.vue'),
//     meta: {
//       description: 'COSMIC EZT manage',
//       displayName: 'COSMIC - cEZT manage',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   }
// ];
const GCAR_ROUTES = [
    {
        path: `/cgcar/search`,
        redirect: {
            name: 'DocumentSearchView',
            query: {
                documentSubType: 'C_GCAR'
            }
        },
        meta: {
            description: 'COSMIC cGCAR creation',
            displayName: 'COSMIC - cGCAR creation',
            supportContexts: [CosmicUserSupportContext.ESOC, CosmicUserSupportContext.EHP],
            permissions: [UserPermission.C_DOCS_GCAR_ADMIN],
            userRoles: []
        }
    },
    {
        path: `/cgcar/create`,
        name: 'GcarCreateView',
        component: () => import(/* webpackChunkName: "DocsGcar" */ '../views/GcarCreateView/GcarCreateView.vue'),
        meta: {
            description: 'COSMIC cGCAR creation',
            displayName: 'COSMIC - cGCAR creation',
            supportContexts: [CosmicUserSupportContext.ESOC, CosmicUserSupportContext.EHP],
            permissions: [UserPermission.C_DOCS_GCAR_ADMIN],
            userRoles: []
        }
    },
    {
        path: `/cgcar/manage`,
        redirect: {
            name: 'DocumentSearchView',
            query: {
                documentSubType: 'C_GCAR'
            }
        },
        meta: {
            description: 'COSMIC cGCAR search',
            displayName: 'COSMIC - cGCAR search',
            supportContexts: [CosmicUserSupportContext.ESOC, CosmicUserSupportContext.EHP],
            permissions: [UserPermission.C_DOCS_GCAR_ADMIN],
            userRoles: []
        }
    },
    {
        path: `/cgcar/manage/:id`,
        name: 'GcarManageView',
        component: () => import(/* webpackChunkName: "DocsGcar" */ '../views/GcarManageView/GcarManageView.vue'),
        meta: {
            description: 'COSMIC cGCAR manage',
            displayName: 'COSMIC - cGCAR manage',
            supportContexts: [CosmicUserSupportContext.ESOC, CosmicUserSupportContext.EHP],
            permissions: [UserPermission.C_DOCS_GCAR_ADMIN],
            userRoles: []
        }
    }
];
// const FRACAS_ROUTES: CosmicRouteConfig[] = [
//   {
//     path: `/cfracas/search`,
//     redirect: {
//       name: 'DocumentSearchView',
//       query: {
//         documentSubType: 'C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM'
//       }
//     },
//     meta: {
//       description: 'COSMIC cFRACAS creation',
//       displayName: 'COSMIC - FRACAS creation',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   },
//   {
//     path: `/cfracas/create`,
//     name: 'FracasCreateView',
//     component: () => import(/* webpackChunkName: "DocsFracas" */ '../views/FracasCreateView/FracasCreateView.vue'),
//     meta: {
//       description: 'COSMIC cFRACAS creation',
//       displayName: 'COSMIC - FRACAS creation',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   },
//   {
//     path: `/cfracas/manage`,
//     redirect: {
//       name: 'DocumentSearchView',
//       query: {
//         documentSubType: 'C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM'
//       }
//     },
//     meta: {
//       description: 'COSMIC cFRACAS search',
//       displayName: 'COSMIC - FRACAS search',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   },
//   {
//     path: `/cfracas/manage/:id`,
//     name: 'FracasManageView',
//     component: () => import(/* webpackChunkName: "DocsFracas" */ '../views/FracasManageView/FracasManageView.vue'),
//     meta: {
//       description: 'COSMIC cFRACAS manage',
//       displayName: 'COSMIC - FRACAS manage',
//       supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
//       permissions: [],
//       userRoles: []
//     }
//   }
// ];
const JF288_ROUTES = [
    {
        path: '/jf288/manage',
        name: 'JF288ManageView',
        component: () => import(/* webpackChunkName: "DocsJf288" */ '../views/JF288ManageView/JF288ManageView.vue'),
        meta: {
            description: 'COSMIC JF288 manage',
            displayName: 'COSMIC - JF288 manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: '/jf288/import',
        name: 'JF288ImportView',
        component: () => import(/* webpackChunkName: "DocsJf288" */ '../views/JF288ImportView/JF288ImportView.vue'),
        meta: {
            description: 'COSMIC JF288 import',
            displayName: 'COSMIC - JF288 import',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: '/jf288/entries/edit/:entryId',
        name: 'JF288EditView',
        component: () => import(/* webpackChunkName: "DocsJf288" */ '../views/JF288EntryEditView/JF288EntryEditView.vue'),
        meta: {
            description: 'COSMIC JF288 manage',
            displayName: 'COSMIC - JF288 manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    }
];
const TPS_ROUTES = [
    {
        path: '/ctps/create',
        name: 'TpsCreateView',
        component: () => import(/* webpackChunkName: "DocsTps" */ '../views/TpsCreateView/TpsCreateView.vue'),
        meta: {
            displayName: 'COSMIC - cTPS creation',
            description: 'COSMIC TPS creation',
            supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: `/ctps/manage`,
        redirect: {
            name: 'DocumentSearchView',
            query: {
                documentSubType: 'C_TPS'
            }
        },
        meta: {
            displayName: 'COSMIC - cTPS search',
            description: 'COSMIC TPS search',
            supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: `/ctps/manage/:id`,
        name: 'TpsManageView',
        component: () => import(/* webpackChunkName: "DocsTps" */ '../views/TpsManageView/TpsManageView.vue'),
        meta: {
            displayName: 'COSMIC - cTPS manage',
            description: 'COSMIC TPS manage',
            supportContexts: [CosmicUserSupportContext.CTSD, CosmicUserSupportContext.xEMU],
            permissions: [],
            userRoles: []
        }
    }
];
const RCA_ROUTES = [
    {
        path: '/crca/create',
        name: 'RCACreateView',
        component: () => import(/* webpackChunkName: "DocsRca" */ '../views/RcaCreateView/RcaCreateView.vue'),
        meta: {
            displayName: 'COSMIC - cRCA creation',
            description: 'COSMIC RCA creation',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: `/crca/manage/:id`,
        name: 'RcaManageView',
        component: () => import(/* webpackChunkName: "DocsRca" */ '../views/RcaManageView/RcaManageView.vue'),
        meta: {
            displayName: 'COSMIC - cRCA manage',
            description: 'COSMIC RCA manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: `/crca/manage`,
        redirect: {
            name: 'DocumentSearchView',
            query: {
                documentSubType: 'C_RCA'
            }
        },
        meta: {
            displayName: 'COSMIC - cRCA search',
            description: 'COSMIC RCA search',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    }
];
const WORKFLOW_ROUTES = [
    {
        path: '/cworkflow/create',
        name: 'WorkflowCreateView',
        component: () => import(/* webpackChunkName: "DocsWorkFlow" */ '../views/WorkflowCreateView/WorkflowCreateView.vue'),
        meta: {
            displayName: 'COSMIC - cWorkflow creation',
            description: 'COSMIC Workflow creation',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: `/cworkflow/manage`,
        redirect: {
            name: 'DocumentSearchView',
            query: {
                documentSubType: 'C_WORKFLOW'
            }
        },
        meta: {
            displayName: 'COSMIC - cWorkflow search',
            description: 'COSMIC Workflow search',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: `/cworkflow/manage/:id`,
        name: 'WorkflowManageView',
        component: () => import(/* webpackChunkName: "DocsWorkFlow" */ '../views/WorkflowManageView/WorkflowManageView.vue'),
        meta: {
            displayName: 'COSMIC - cWorkflow manage',
            description: 'COSMIC Workflow manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    }
];
export const ReportingRoutes = [
    {
        path: `/reporting`,
        name: 'ReportingSearchView',
        component: () => import(/* webpackChunkName: "DocsReporting" */ '../views/ReportingSearchView/ReportingSearchView.vue'),
        meta: {
            description: 'All COSMIC Docs reports',
            displayName: 'Reporting Search',
            doNotList: true,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/pending_approvals`,
        name: 'ReportPendingApprovalsView',
        component: () => import(
        /* webpackChunkName: "DocsReporting" */ '../views/ReportPendingApprovalsView/ReportPendingApprovalsView.vue'),
        meta: {
            color: EntityType.CHANGE_SET,
            description: 'All items awaiting a step or task approval will be listed here.',
            entityRelationships: [EntityType.CHANGE_SET],
            displayName: 'Pending approvals',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/user_change_set_approvals`,
        name: 'ReportMyApprovalsView',
        component: () => import(/* webpackChunkName: "DocsReporting" */ '../views/ReportMyApprovalsView/ReportMyApprovalsView.vue'),
        meta: {
            color: EntityType.CHANGE_SET,
            description: 'All items that you can approve will be listed here.',
            entityRelationships: [EntityType.CHANGE_SET],
            displayName: 'My Approval Queue',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/rca_status`,
        name: 'ReportRcaStatusView',
        component: () => import(/* webpackChunkName: "DocsReporting" */ '../views/ReportRcaStatusView/ReportRcaStatusView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Displays a breakdown of the current cRCAs logged into COSMIC.',
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'cRCA Status',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/workflow_status`,
        name: 'ReportWorkflowStatusView',
        component: () => import(/* webpackChunkName: "DocsReporting" */ '../views/ReportWorkflowStatusView/ReportWorkflowStatusView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Displays a breakdown of the current cWorkflows logged into COSMIC.',
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'cWorkflow Status',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [],
            userRoles: []
        }
    }
];
const ALL_ROUTES = [
    {
        path: `/`,
        name: 'HomeView',
        component: HomeView,
        meta: {
            displayName: 'COSMIC Docs Home',
            description: 'Home page for COSMIC Docs',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/search`,
        name: 'DocumentSearchView',
        component: () => import(/* webpackChunkName: "Docs" */ '../views/DocumentSearchView/DocumentSearchView.vue'),
        meta: {
            displayName: 'COSMIC - Docs - search',
            doNotList: true,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    ...AdminSharedRoutes,
    ...ContactSharedRoutes,
    // ...DR_ROUTES,
    // ...EZT_ROUTES,
    ...GCAR_ROUTES,
    // ...FRACAS_ROUTES,
    ...HardwareListSharedRoutes,
    ...JF288_ROUTES,
    ...LocationSharedRoutes,
    ...RCA_ROUTES,
    ...ReportingRoutes,
    ...TPS_ROUTES,
    ...UserSharedRoutes,
    ...WORKFLOW_ROUTES,
    ...NonnominalSharedRoutes // must be at the end
];
export default new RouterLogUtility(VueRouter, ALL_ROUTES);
