var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "single_line_display_part"
  }, [_vm.drawingNumber ? _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.drawingNumber))]) : _vm._e(), _vm.asBuiltNumber && _vm.asBuiltNumber !== _vm.DB_DEFAULT ? _c('span', [_c('small', {
    staticClass: "label"
  }, [_vm._v("ab")]), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.asBuiltNumber))])]) : _vm._e(), _vm.side && _vm.side !== _vm.Side.NONE ? _c('span', [_c('small', {
    staticClass: "label"
  }, [_vm._v("sd")]), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.SideDisplay.get(_vm.side)))])]) : _vm._e(), _vm.size && _vm.size !== _vm.DB_DEFAULT ? _c('span', [_c('small', {
    staticClass: "label"
  }, [_vm._v("sz")]), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.size))])]) : _vm._e(), _vm.lotNumber ? _c('span', [_c('small', {
    staticClass: "label"
  }, [_vm._v("ln")]), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.lotNumber))])]) : _vm._e(), _vm.serialNumber ? _c('span', [_c('small', {
    staticClass: "label"
  }, [_vm._v("sn")]), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.serialNumber))])]) : _vm._e(), !_vm.drawingNumber && !_vm.asBuiltNumber && _vm.asBuiltNumber !== _vm.DB_DEFAULT && !_vm.side && _vm.side !== _vm.Side.NONE && !_vm.size && _vm.size !== _vm.DB_DEFAULT && !_vm.lotNumber && !_vm.serialNumber ? _c('span', [_c('i', [_vm._v("no descriptive data")])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };